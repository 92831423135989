import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"


import ImgTarjeta from "../images/cuenta-estandar/tarjeta.png"
import ImgTarjetaVirtual from "../images/cuenta-estandar/tarjeta-virtual.png"
import ImgMundo from "../images/cuenta-estandar/mundo.gif"
import ImgAbreTuCuenta from "../images/cuenta-estandar/abre-tu-cuenta.gif"
import ImgMueveTuDinero from "../images/cuenta-estandar/mueve-tu-dinero-con-tus-reglas.gif"
import ImgServiciosEnLinea from "../images/cuenta-estandar/servicios-en-linea.gif"


export const StyledText = styled.div`
  display: inline-block;
  max-width: 32rem;
`

export const StyledHeading = styled.h2`
  line-height: 1.125em;
  letter-spacing: -0.025em;
  color: rgb(25, 28, 31);
  font-size: 2.5rem;
  margin: 0px;

  @media (min-width: 1081px) {
    font-size: 3rem;
  }
`

export const StyledImage = styled.div`
  @media (max-width: 1080px) {
    margin-top: 6rem;
    max-height: 32rem;
  }
`

export const StyledAnimation = styled.div`
  margin: 0px auto;
  box-sizing: border-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const StyledSlide = styled.div`
  padding: 0px 3rem;
  margin: auto;
  max-width: 86rem;
  min-height: 100vh;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  flex-direction: row;

  @media (max-width: 519px) {
    padding: 0px 1.5rem;
    margin: 8.25rem 0px 12rem;
    height: auto;
    min-height: 0px;
    max-height: none;
  }

  @media (max-width: 1080px) {
    margin: 70px 0px;
    flex-direction: column-reverse;
    -moz-box-pack: center !important;
    justify-content: center !important;
    -moz-box-align: center !important;
    align-items: center !important;
    max-height: none;

    &:first-child {
      margin-top: auto;
    }

    & > div:first-child {
      margin-right: 0px;
    }

    & h2,
    & p {
      text-align: center;
    }
  }

  @media (min-width: 1081px) {
    ${ props => props.reverse ? "flex-direction: row-reverse" : "" };

    & ${StyledText} {
      ${ props => props.reverse ? "" : "margin-right: 4rem" };
    }

    & ${StyledImage} {
      ${ props => props.reverse ? "margin-right: 4rem" : "" };
    }
  }
`


const BasicAccountPage = () => (
  <Layout>
    <SEO title="Cuenta Estandar" />
    <StyledSlide>
      <StyledText>
        <StyledHeading>¡La cuenta para ti!</StyledHeading>
        <p>
          ¿A ti también te decepcionaron los bancos tradicionales?<br />
          <br />
          Con KKO, tienes la seguridad de saber exactamente dónde gastas tu dinero porque tú y nadie más tiene el control de tus finanzas.
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgTarjeta} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
    <StyledSlide reverse={true}>
      <StyledText>
        <StyledHeading>Abre una cuenta gratuita en 5 minutos</StyledHeading>
        <p>
          ¿Cómo abrir una cuenta bancaria?<br />
          <br />
          Puedes ir al banco, hacer filas, pagar costos injustificados...<br />
          También puedes abrir una cuenta KKO totalmente gratis, sin comisiones, en 5 minutos desde tu sofá.
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgAbreTuCuenta} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
    <StyledSlide>
      <StyledText>
        <StyledHeading>Mueve tu dinero con tus propias reglas</StyledHeading>
        <p>
          Establece presupuestos inteligentes, envía y pide dinero a tus amigos, realiza pagos recurrentes (como el pago de tu renta, o por ejemplo la quiniela).<br />
          <br />
          También puedes definir tus límites de gastos en general o por categoría.
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgMueveTuDinero} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
    <StyledSlide reverse={true}>
      <StyledText>
        <StyledHeading>Paga o retira en más de 210 países</StyledHeading>
        <p>
          Ya sea que planeas viajar o necesitas enviar dinero al extranjero, con KKO ya no tendrás barreras financieras. KKO te acompaña con las mejores condiciones en todo momento.
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgMundo} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
    <StyledSlide>
      <StyledText>
        <StyledHeading>Tarjeta virtual</StyledHeading>
        <p>
          Te imaginas dedicar una sola tarjeta para pagar la luz, otra para el agua etc...?<br />
          KKO lo hace posible ofreciéndote tarjetas virtuales que te permiten realizar compras en línea, pagos de servicios y mucho más!<br />
          <br />
          ¡Dile adiós a los cargos extraños!
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgTarjetaVirtual} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
    <StyledSlide reverse={true}>
      <StyledText>
        <StyledHeading>Realiza pagos de servicios</StyledHeading>
        <p>
          Ahora puedes pagar tus facturas de agua, luz, internet y mucho más, directamente desde la App.<br />
          <br />
          Simplemente escanea el código de barras o toma una foto de tu recibo e indica la cantidad a pagar.<br />
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgServiciosEnLinea} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
  </Layout>
)

export default BasicAccountPage
